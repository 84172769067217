export const changeTheme = () => ({ type: "CHANGE_UI_THEME" });
export const setTheme = (theme) => ({ type: "SET_UI_THEME", payload: theme });
export const setCurrentTable = (tableNumber) => ({
  type: "SET_CURRENT_TABLE",
  payload: tableNumber,
});

export const setNotify = (error, errorMsg) => ({
  type: "SET_NOTIFY",
  payload: { error, errorMsg },
});
export const setConfirm = (show, id, name) => ({
  type: "SET_CONFIRM",
  payload: { show, id, name },
});
export const setNotifyShow = (show) => ({
  type: "SET_NOTIFY_SHOW",
  payload: show,
});
export const setSearch = (query) => ({
  type: "SET_SEARCH",
  payload: query.toLowerCase(),
});
export const setBusy = (busy) => ({
  type: "SET_BUSY",
  payload: busy,
});
export const toggleExtend = () => ({
  type: "TOGGLE_EXTEND",
});
