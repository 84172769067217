import React from "react";

import CardComponent from "./cardsMultiTable/CardComponent";
import Loader from "../Loader";
import NewComponent from "./cardsMultiTable/NewComponents";

const CardsTable = ({ data }) => {
  if (typeof data === "undefined") {
    return (
      <div className="card-multi-grid">
        <NewComponent />
      </div>
    );
  }
  if (!data || data.length === 0 || !Array.isArray(data)) {
    return <Loader />;
  }

  return (
    <div className="card-multi-grid">
      {data.map((item) => (
        <CardComponent key={item.category_id} item={item} />
      ))}
      <NewComponent />
    </div>
  );
};

export default CardsTable;
