import React from "react";
import { Dialog } from "@lightspeed/flame/Dialog";
import { useSelector, useDispatch } from "react-redux";

import { setConfirm } from "../redux/actions/uiActions";
import {
  removeShopCategoriesMulti,
  getShopCategoriesMulti,
} from "../redux/actions/databaseActions";

const ConfirmDelete = () => {
  const ui = useSelector((state) => state.ui);
  const database = useSelector((state) => state.database);
  const userState = useSelector((state) => state.userState);
  const dispatch = useDispatch();
  return (
    <Dialog
      title={ui.confirm.name}
      message={`Are you sure you want to remove this item?`}
      isOpen={ui.confirm.show}
      onCancel={() => dispatch(setConfirm(false))}
      onConfirm={() => {
        dispatch(setConfirm(false));
        dispatch(
          removeShopCategoriesMulti(
            database.currentCompany.id,
            userState.user.user_id,
            database.currentShop.shop_id,
            ui.confirm.itemId,
            getShopCategoriesMulti
            
          )
        );
      }}
      cancelText="Cancel"
      confirmText="Delete"
      type="danger"
    />
  );
};

export default ConfirmDelete;
