import React from "react";
import { Table, Thead, Tr, Th } from "@lightspeed/cirrus-table";
import { Button } from "@lightspeed/flame/Button";
import { Card } from "@lightspeed/flame/Card";
import { Icon } from "@lightspeed/flame/Icon";

import Row from "./Row";
import Loader from "../../Loader";

import { useDispatch } from "react-redux";
import { setConfirm } from "../../../redux/actions/uiActions";

const CardComponent = ({ item }) => {
  const dispatch = useDispatch();
  if (
    !item.details ||
    item.details.length === 0 ||
    !Array.isArray(item.details)
  ) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <div className="card-table-title card-table-title-multi">
        <p>{item.category_name}</p>
        <Button
          onClick={() =>
            dispatch(setConfirm(true, item.category_id, item.category_name))
          }
          variant="small"
          fill="true"
        >
          {/* Remove */}
          {/* <Icon name="cross" /> */}
          <Icon name="delete" />
        </Button>
      </div>
      <Table>
        <Thead>
          <Tr>
            <Th width="25%">Account</Th>
            <Th width="40%">Account Description</Th>
            <Th width="10%" textAlign="right">
              Amount
            </Th>
            <Th width="10%" textAlign="right">
              Actions
            </Th>
          </Tr>
        </Thead>
        <tbody>
          {item.details.map((itemRow) => (
            <Row
              key={itemRow.multi_id}
              item={itemRow}
              categoryId={item.category_id}
            />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default CardComponent;
