import uiReducer from "./uiReducer";
import userReducer from "./userReducer";
import databaseReducer from "./databaseReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  ui: uiReducer,
  userState: userReducer,
  database: databaseReducer,
});

export default rootReducer;
