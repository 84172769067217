import React, { useState } from "react";

import SideBar from "./home/SideBar";
import LocationBar from "./home/LocationBar";
import LocationTopBar from "./home/LocationTopBar";
import CategorieBar from "./home/CategorieBar";
import TableContent from "./home/TableContent";
import Dash from "./home/Dash";

import Busy from "./Busy";
import Notify from "./Notify";
import { useSelector } from "react-redux";

import dots from "../assets/dots.svg";

const Home = () => {
  const [dashMain, setDashMain] = useState(true);
  const [page, setPage] = useState(0);
  const [extended, setExtended] = useState(false);
  const ui = useSelector((state) => state.ui);
  const [showSideBar, setShowSideBar] = useState(true);
  return (
    <div className="home">
      {showSideBar && <SideBar setDashMain={setDashMain} />}

      <div
        className="centered side-bar-toggle"
        onClick={(_) => {
          setShowSideBar((old) => !old);
        }}
      >
        <img src={dots} alt="burger dots" />
      </div>
      {dashMain ? (
        <Dash />
      ) : (
        <div className="main-section relative">
          {ui.notify.show && <Notify />}
          {ui.busy && <Busy />}
          <LocationTopBar setExtended={setExtended} extended={extended} />
          <LocationBar extended={extended} />
          <CategorieBar page={page} setPage={setPage} />
          <TableContent page={page} />
        </div>
      )}
    </div>
  );
};

export default Home;
