import React from "react";
import { Table, Thead, Tr, Th } from "@lightspeed/cirrus-table";

import { Card } from "@lightspeed/flame/Card";

import Row from "./cardTable/Row";
import Loader from "../Loader";

const CardTable = ({ title = "Title", data, shopShow }) => {
  if (!data || data.length === 0 || !Array.isArray(data)) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <div className="card-table-title">{title}</div>
      <Table>
        <Thead>
          <Tr>
            <Th width="15%">Company Id </Th>
            {shopShow && <Th width="15%">Shop Id </Th>}
            <Th width="20%">Account Code </Th>
            <Th width="20%">Account Name </Th>
            <Th width="10%" textAlign="right">
              Actions{" "}
            </Th>
          </Tr>
        </Thead>
        <tbody>
          <Row shopShow={shopShow} item={data[0]} />
        </tbody>
      </Table>
    </Card>
  );
};

export default CardTable;
