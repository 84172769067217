import React, { useEffect } from "react";

import { Alert } from "@lightspeed/flame/Alert";
import { useDispatch, useSelector } from "react-redux";

import { setNotifyShow } from "../redux/actions/uiActions";

const Notify = () => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  const typeTuple = ["success", "danger"];
  const titleTuple = ["Success!", "Warning"];
  const msgTuple = [
    "Updated successfully",
    "Oops, something went wrong, please try again later",
  ];
  const magicNum = ui.notify.error ? 1 : 0;

  useEffect(() => {
    setTimeout(() => dispatch(setNotifyShow(false)), 1000);
  }, [dispatch]);
  return (
    <div className="notification">
      <Alert type={typeTuple[magicNum]} title={titleTuple[magicNum]}>
        {msgTuple[magicNum]}
        <span style={{ fontSize: "10px" }}>{ui.notify.errorMsg}</span>
      </Alert>
    </div>
  );
};

export default Notify;
