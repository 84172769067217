import React from "react";

import LocationButton from "./location/LocationButton";
import { nanoid } from "nanoid";

import { useSelector } from "react-redux";
import Loader from "../Loader";

const LocationBar = ({ extended }) => {
  const database = useSelector((state) => state.database);
  const userState = useSelector((state) => state.userState);

  if (database.currentCompany.direct === true) {
    return <div style={{ margin: "16px" }} />;
  }

  if (
    !database.currentCompany.filteredShops ||
    database.currentCompany.filteredShops.length === 0
  ) {
    return <Loader location={true} />;
  }

  return (
    <div className={`location-bar ${extended ? "location-bar-extended" : ""}`}>
      {database.currentCompany.filteredShops.map((location) => (
        <LocationButton
          key={nanoid()}
          location={location}
          company={database.currentCompany}
          shop={database.currentShop}
          user={userState.user}
        />
      ))}
    </div>
  );
};

export default LocationBar;
