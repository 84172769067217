import React, { useState } from "react";
import { Button } from "@lightspeed/flame/Button";
import { Card } from "@lightspeed/flame/Card";
import { Select } from "@lightspeed/flame/Select";

import plus from "../../../assets/plus.svg";

import {
  getShopCategoriesMultiFree,
  addShopCategoriesMulti,
  getShopCategoriesMulti,
} from "../../../redux/actions/databaseActions";
import { useDispatch, useSelector } from "react-redux";

const NewComponent = () => {
  const userState = useSelector((state) => state.userState);
  const database = useSelector((state) => state.database);
  const dispatch = useDispatch();
  const [showAdd, setShowAdd] = useState(false);
  const [selectValue, setSelectValue] = useState("");

  if (showAdd) {
    return (
      <Card className="min-new-component">
        <div className="card-table-title ">
          <p>Add new item?</p>
        </div>
        <div className="select-wrapper">
          <Select
            value={selectValue}
            onChange={(e) => setSelectValue(e.target.value)}
          >
            {database.currentShop.categoriesFree.map((item) => (
              <option key={item.category_id} value={item.category_id}>
                {item.category_name}
              </option>
            ))}
          </Select>
          <div className="buttons-wrapper-multi">
            <Button
              onClick={() => setShowAdd(false)}
              style={{ marginRight: "16px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setShowAdd(false);
                dispatch(
                  addShopCategoriesMulti(
                    database.currentCompany.id,
                    userState.user.user_id,
                    database.currentShop.shop_id,
                    parseInt(selectValue),
                    getShopCategoriesMulti
                  )
                );
              }}
              variant="primary"
              fill="true"
            >
              Confirm
            </Button>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <div
      onClick={() => {
        setShowAdd(true);
        dispatch(
          getShopCategoriesMultiFree(
            database.currentCompany.id,
            userState.user.user_id
          )
        );
      }}
      className="new-component min-new-component"
    >
      <div className="add-svg-wrapper">
        <img src={plus} alt="add" />
      </div>
    </div>
  );
};

export default NewComponent;
