import React, { useState, useEffect } from "react";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { Button } from "@lightspeed/flame/Button";
import { Input } from "@lightspeed/flame/Input";

import { useDispatch, useSelector } from "react-redux";
import * as databaseActions from "../../../redux/actions/databaseActions";
const Row = ({ item, categoryId }) => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const userState = useSelector((state) => state.userState);
  const database = useSelector((state) => state.database);
  const [edit, setEdit] = useState(false);
  const [changed, setChanged] = useState(false);
  const [inputBox1, setInputBox1] = useState("");
  const [inputBox2, setInputBox2] = useState("");
  const [inputBox3, setInputBox3] = useState("");
  const normalAccount = item.account_id === null ? "" : item.account_id;
  const normalAccountName = item.account_name === null ? "" : item.account_name;
  const normalAmount = item.amount === null ? "" : item.amount.toFixed(2);

  useEffect(() => {
    setInputBox1(normalAccount);
    setInputBox2(normalAccountName);
    setInputBox3(normalAmount);
    if (
      normalAccount === "" &&
      normalAccountName === "" &&
      normalAmount === (0).toFixed(2)
    ) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [normalAccount, normalAccountName, normalAmount]);
  useEffect(() => {
    if (
      normalAccount === inputBox1 &&
      normalAccountName === inputBox2 &&
      normalAmount === inputBox3
    ) {
      setChanged(false);
    } else {
      setChanged(true);
    }
  }, [
    inputBox1,
    inputBox2,
    inputBox3,
    normalAccount,
    normalAccountName,
    normalAmount,
  ]);

  const handleUpdate = () => {
    dispatch(
      databaseActions.updateShopCategoriesMulti(
        database.currentCompany.id,
        userState.user.user_id,
        database.currentShop.shop_id,
        categoryId,
        parseInt(item.multi_id),
        inputBox1,
        inputBox2,
        parseFloat(inputBox3),
        databaseActions.getShopCategoriesMulti
      )
    );
    setEdit(false);
  };

  return (
    <Tr
      textAlign="right"
      className={`${ui.theme === "flame" ? "" : "dark-table-row"}`}
      onDoubleClick={() => {
        setInputBox1(normalAccount);
        setInputBox2(normalAccountName);
        setInputBox3(normalAmount);
        setEdit(true);
      }}
    >
      <Td width="15%">
        {!edit && (
          <div className="row-input-wrapper">
            {inputBox1}
            <div className="flex" />
          </div>
        )}

        {edit && (
          <div className="row-input-wrapper">
            <div>
              <Input
                className="input-wrapper-row-inputbox"
                type="text"
                size="small"
                value={inputBox1}
                onChange={(e) => setInputBox1(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate();
                  }
                }}
              />
            </div>
          </div>
        )}
      </Td>
      <Td width="20%">
        {!edit && (
          <div className="row-input-wrapper">
            {inputBox2}
            <div className="flex" />
          </div>
        )}

        {edit && (
          <div className="row-input-wrapper">
            <div>
              <Input
                className="input-wrapper-row-inputbox"
                type="text"
                size="small"
                value={inputBox2}
                onChange={(e) => setInputBox2(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate();
                  }
                }}
              />
            </div>
          </div>
        )}
      </Td>

      <Td width="20%">
        {!edit && (
          <div className="right-align">
            ${inputBox3}
            <div className="flex" />
          </div>
        )}

        {edit && (
          <div className="row-input-wrapper">
            <div>
              <Input
                className="input-wrapper-row-inputbox right-align"
                type="number"
                size="small"
                value={inputBox3}
                onChange={(e) => setInputBox3(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate();
                  }
                }}
              />
            </div>
          </div>
        )}
      </Td>
      <Td width="10%" textAlign="right">
        {!edit && (
          <div className="row-input-wrapper-end">
            <Button
              onClick={(_) => {
                setInputBox1(normalAccount);
                setInputBox2(normalAccountName);
                setInputBox3(normalAmount);
                setEdit(true);
              }}
              size="small"
              fill="true"
            >
              Edit
            </Button>
          </div>
        )}

        {edit && (
          <div className="row-input-wrapper-end">
            {changed ? (
              <Button onClick={handleUpdate} size="small" fill="true">
                Update
              </Button>
            ) : inputBox1 === "" &&
              inputBox2 === "" &&
              inputBox3 === (0).toFixed(2) ? null : (
              <Button
                onClick={() => {
                  setInputBox1(normalAccount);
                  setInputBox2(normalAccountName);
                  setInputBox3(normalAmount);
                  setEdit(false);
                }}
                size="small"
                fill="true"
              >
                Cancel
              </Button>
            )}
          </div>
        )}
      </Td>
    </Tr>
  );
};

export default Row;
