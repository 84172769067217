import { Card } from "@lightspeed/flame/Card";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";
import styled from "@emotion/styled";
//import { useForm } from "react-hook-form";
//import { uiSelector } from "../../../redux/slices/uiSlice";
import { IconSync } from "@lightspeed/flame/Icon/Sync";
import { IconDownload } from "@lightspeed/flame/Icon/Download";
import { FaSave } from "react-icons/fa";
//import { useDispatch } from "react-redux";
//import { saveAs } from 'file-saver';
import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";

// yesterday date will be default
const getYesterdayDate = () => {
  const current = new Date();
  current.setDate(current.getDate() - 1); 
  return current.toISOString().split('T')[0]; // YYYY-MM-DD
};

const StyledCard = styled(Card)`
  margin-left: 16px;
  padding: 15px;
`;

const StyledGroup = styled.div`
  margin: 16px 0;
  label {
    margin-left: 4px;
    margin-bottom: 4px;
    display: block;
  }
`;

// reusable component taking prop to create start and end date later
const InputGroup = ({ name, label, defaultValue, onChange }) => {
  return (
    <StyledGroup>
      <label htmlFor={name}>{label}</label>
      <Input 
        id={name} 
        name={name} 
        type="date" 
        defaultValue={defaultValue}
        onChange={onChange} 
      />
    </StyledGroup>
  );
};

// Select list for reports list
const SelectGroup = styled.div`
  margin: 16px 0;
  
  label {
    margin-left: 4px;
    margin-bottom: 4px;
    display: block;
  }

  .select-and-button {
    display: flex; /* dropdown and button next to another */
    align-items: center; 
    gap: 4px; 
  }

  select {
    flex-grow: 1; 
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;

const SmallButton = styled(Button)`
  padding: 4px 8px;  
  font-size: 12px;  
  display: flex;
  align-items: center;
  gap: 4px;  

  svg {
    font-size: 12px; 
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); 
  z-index: 9999; 
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const SaveButton = styled.button`
  background-color: #28a745;  
  color: white;
  padding: 12px 24px; 
  border: none; 
  border-radius: 3px; 
  margin-right: 10px; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  cursor: pointer; 

  &:hover {
    background-color: #218838;  
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15); 
  }

  &:active {
    background-color: #1e7e34;  
    box-shadow: none; 
  }

  &:focus {
    outline: none; 
  }
`;

const CancelButton = styled.button`
  background-color: #dc3545;  
  color: white;
  padding: 12px 24px; 
  border: none; 
  border-radius: 3px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: #c82333;  
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    background-color: #bd2130;  
    box-shadow: none;
  }

  &:focus {
    outline: none; 
  }
`;


const Modal = ({ show, handleClose, handleSave, reportName, setReportName }) => {
  if (!show) return null; 

  return (
    <ModalBackground>
      <ModalContent>
        <h3>Confirm Save</h3>
        <p>Save report settings?</p>
        {/* Report name */}
        <div style={{ marginBottom: '15px' }}>
           <Input
             id="reportName"
             name="reportName"
             value={reportName}
             onChange={(e) => setReportName(e.target.value)}
           />
         </div>
        <SaveButton onClick={handleSave}>Save</SaveButton>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
      </ModalContent>
    </ModalBackground>
  );
};

// chiled component DatePicker - added onReportChange prop to accept
const DatePicker = ({ updateAction, tableRef, userId, companyId, companyType, onReportChange,  onSaveReport }) => {
  const yesterday = getYesterdayDate();
  const [fromDate, setFromDate] = useState(yesterday);  // default start date state
  const [toDate, setToDate] = useState(yesterday);      // default end date state
  const [loading, setLoading] = useState(false);        // loading state used for SYNC button
  const [reportList, setReportList] = useState([]);     // state for report list in dropdown
  const [selectedReport, setSelectedReport] = useState(""); // state for selected report
  const [showModal, setShowModal] = useState(false);
  const [reportName, setReportName] = useState("");
  const [reportChanged, setReportChanged] = useState(false); // track report change (from Select Report list) - to trigger data reload behind SYNC
  const [savedReportName, setSavedReportName] = useState(""); // persistent state for report name
  const [savedReportId, setSavedReportId] = useState("");     // persistent state for report ID

 
    // xoption based on company type
    const getXOption = () => {
      if (companyType === "RESTOK") {
        return "get_report_list_k";
      } else if (companyType === "RESTOL") {
        return "get_report_list_l";
      } else {
        return "get_report_list"; // Default case
      }
    };

  // fetch the list of reports just when component mounts
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await fetch("https://mapapimssql.datastems.com/api/admin/report/settings", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            token_user_id: userId,
            company_id: companyId,
            report_name: "",
            xoption:  getXOption(),//"get_report_list",
          })
        });
        const data = await response.json();
        //console.log("report list:", data); 
        setReportList(data); 
      } catch (error) {
        console.error("Error fetching report list:", error);
      }
    };

    fetchReports();
  }, [userId, companyId]); // dependency array to run useEffect again if userId or companyId states change

  const handleReportChange = (e) => {
    const selectedReport = reportList.find(report => report.report_name === e.target.value);
  
    setSelectedReport(e.target.value);  // temp state for current report
    setReportName(selectedReport?.report_name || ""); // for modal
    
    // persist report details in state for SYNC
    setSavedReportName(selectedReport?.report_name || "");
    setSavedReportId(selectedReport?.id || "");
  
    console.log("Updated saved report name after selection:", savedReportName);
    console.log("Updated saved report ID after selection:", savedReportId);
  
    // data reload with selected report details
    //onReportChange(selectedReport?.rows.split(',').map(row => row.trim()), selectedReport?.id, selectedReport?.report_name);
    onReportChange(
      selectedReport?.rows.split(',').map(row => row.trim()), // rows
      selectedReport?.id, // reportId
      selectedReport?.report_name, // reportName
      selectedReport?.sum_field ? selectedReport?.sum_field.split(',').map(field => field.trim()) : [], // sum_field (vals)
      selectedReport?.cols_fields.split(',').map(col => col.trim()) // cols_fields
    );    
    setReportChanged(true);  // flag to trigger sync
  };
  
  
  /*const onSubmit = (e) => {
    dispatch(
      updateAction({
        ...e,
      })
    );*/

  useEffect(() => {
     if (reportChanged) {
      handleSync(); // trigger original sync when report columns are changed by Report List selection
      setReportChanged(false); // reset flag after sync
     }
  }, [reportChanged]); // watch when reportChanged is set to true

  const handleSync = async () => {
    console.log("Sync triggered");
  
    // Use the persistent state variables
    const reportNameToSend = savedReportName || reportName || ""; //report_name is always passed
    const reportIdToSend = savedReportId || ""; // ID is sent if available
  
    console.log("Syncing with payload: ", {
      fromDate,
      toDate,
      reportName: reportNameToSend, 
      reportId: reportIdToSend,    
    });
  
    if (!reportNameToSend) {
      console.error("No report_name found! Something went wrong with state.");
    }
  
    setLoading(true); // Start loading spinner
  
    try {
      await updateAction(fromDate, toDate, reportNameToSend, reportIdToSend); 
    } finally {
      setLoading(false); // Stop spinner
    }
  };
  
    
  // Export to Excel function
  const exportToExcel = () => {
    if (tableRef && tableRef.current) {
      // Table.js exposed export function
      tableRef.current.export(); 
    }
  };

  const exportToCSV = () => {
    if (tableRef && tableRef.current) {
      // Table.js exposed CSV export function
      tableRef.current.exportToCSV();  
    }
  };

  // show modal when click save icon
  const handleSaveClick = () => {
    //if no report is selected, first set the name to empty for new report
    if (!selectedReport) {
      setReportName("");  
    }

    setShowModal(true);  
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // func for saving report after confirmation
  const handleConfirmSave = async () => {
    // use reportName from modal (last entered name) for saving!!!
    await updateReportWithCurrentColumns(reportName); // pass the new report name
    
    // refetch reports and update columns with new saved report name!!!
    refetchReportsAndUpdateColumns(reportName); // new reportName passed
  
    setTimeout(() => {
      if (onSaveReport) {
        console.log("Calling onSaveReport with:", savedReportId, reportName); 
        onSaveReport(savedReportId, reportName);  // use updated reportName
      }
      setShowModal(false); // close modal after saving
    }, 900);
  };
  

  const updateReportWithCurrentColumns = async (updatedReportName) => {
    if (tableRef && tableRef.current) {
      const { rows, cols, vals } = tableRef.current.getColumns(); // current columns!
  
      const reportDetails = {
        report_name: updatedReportName, // updated report name passed as argument
        rows: rows.join(','), // rows array to string
        cols_fields: cols.join(','), // cols array to string
        sum_field: vals.join(','), // vals array to string
        token_user_id: userId,
        company_id: companyId,
        xoption: getXOption(), // save settings!!
      };
  
      if (!selectedReport) {
        // new report: save the new report
        await saveReportSettings(reportDetails);
      } else {
        // existing report: update report
        const reportToUpdate = reportList.find(report => report.report_name === selectedReport);
        if (reportToUpdate) {
          const updatedReport = {
            ...reportToUpdate,
            rows: rows.join(','), // update with new rows
            cols_fields: cols.join(','), // udate with new cols
            sum_field: vals.join(','), // update with new vals
            report_name: updatedReportName, // use updated report name
          };
          await saveReportSettings(updatedReport); // save updated report!
        }
      }
    }
  };
  

  const refetchReportsAndUpdateColumns = async (reportName) => {
    try {
      const response = await fetch("https://mapapimssql.datastems.com/api/admin/report/settings", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token_user_id: userId, company_id: companyId, xoption: "get_report_list" })
      });
      const data = await response.json();
      setReportList(data);
  
      // update saved report details after refetching
      const updatedReport = data.find(report => report.report_name === reportName);
      if (updatedReport) {
        // upd state with saved report
        setSavedReportId(updatedReport.report_id || "");
        setSavedReportName(updatedReport.report_name || "");
        onReportChange(updatedReport.rows.split(',').map(row => row.trim()), updatedReport.id, updatedReport.report_name, updatedReport.sum_field.split(','), updatedReport.cols_fields.split(','));
      }
    } catch (error) {
      console.error("Error re-fetching the report list:", error);
    }
  };
  
  

const saveReportSettings = async (updatedReport) => {
  try {
    const response = await fetch("https://mapapimssql.datastems.com/api/admin/report/settings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ...updatedReport,
        xoption: "save_settings"
      })
    });
    const data = await response.json();
    console.log("Report updated:", data);
  } catch (error) {
    console.error("Error saving report settings:", error);
  }
};

  return (
    <>
      {loading && (
        <LoadingOverlay>
          <ClipLoader size={50} color="#36d7b7" />
        </LoadingOverlay>
      )}
    <StyledCard>
      <form onSubmit={(e) => e.preventDefault()}>
        <SelectGroup>
          {/*<label htmlFor="reportSelect">Select Report:</label>*/}
          <div className="select-and-button">
          <select
            id="reportSelect"
            value={selectedReport}
            onChange={handleReportChange}
          >
          <option value="">Select Report...</option>
            {reportList.map((report) => (
              <option key={report.id} value={report.report_name}>
                {report.report_name}
              </option>
             ))}
          </select>
        <SmallButton type="button" onClick={handleSaveClick} title="Save current report settings">
          <FaSave /> 
        </SmallButton>
        </div>
        </SelectGroup>
        <InputGroup 
          name="start_date" 
          label="Start Date:" 
          defaultValue={fromDate} 
          onChange={(e) => setFromDate(e.target.value)}
        />
        <InputGroup 
          name="end_date" 
          label="End Date:" 
          defaultValue={toDate} 
          onChange={(e) => setToDate(e.target.value)}
        />
        <ButtonWrapper>
       {/*  <Button type="submit"> */}
          <Button type="button" onClick={handleSync}>
            Sync
            <IconSync />
          </Button>
          <Button type="button" onClick={exportToExcel}>
            Export to Excel
            <IconDownload />
          </Button>
          <Button type="button" onClick={exportToCSV}>
            Export to CSV
            <IconDownload />
          </Button>
        </ButtonWrapper>
      </form>
    </StyledCard>
    {/* Modal */}
    <Modal
        show={showModal}
        handleClose={handleCloseModal}
        handleSave={handleConfirmSave}
        reportName={reportName} // pass report name to modal
        setReportName={setReportName} // modal can update report name
      />
    </>
  );
};

export default DatePicker;
