import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import gear from "../assets/gear.svg";

import { setBusy } from "../redux/actions/uiActions";

const Busy = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => dispatch(setBusy(false)), 1000);
  }, [dispatch]);

  return (
    <div className="busy">
      <img src={gear} alt="gear" />
      <p>Working</p>
    </div>
  );
};

export default Busy;
