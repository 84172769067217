export const customSortGen = (sortParam) => (a, b) => {
  const companyA = a[sortParam];
  const companyB = b[sortParam];

  let comparison = 0;
  if (companyA > companyB) {
    comparison = 1;
  } else if (companyA < companyB) {
    comparison = -1;
  }
  return comparison;
};
export const customSortGenString = (sortParam) => (a, b) => {
  const companyA = String(a[sortParam]).toLowerCase();
  const companyB = String(b[sortParam]).toLowerCase();

  let comparison = 0;
  if (companyA > companyB) {
    comparison = 1;
  } else if (companyA < companyB) {
    comparison = -1;
  }
  return comparison;
};
